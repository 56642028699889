<template>
	<div class="Ober_case">
		<vue-headful
			title="Slumberland | Groupe Ober"
			description=""
		/>
		<CaseTop
			title="Groupe Ober"
			subTitle="Site B2B <br/>Mission : conseil, web design, développement, accompagnement"
			cover="Ober_cover.jpg"
		/>
		<ContentList
			alignText="center"
			:columns=1
			title="Mission et enjeux"
			id="mission"
		>
			<p>
        Slumberland accompagne depuis 2009 Ober, un groupe industriel français spécialisé dans la création de panneaux décoratifs. Il fonctionne sur un modèle B2B2C : le site s’adresse principalement aux prescripteurs (architectes et décorateurs d’intérieur) et aux menuisiers et agenceurs qui mettent en œuvre les produits.
      </p>
      <p>
        Suite au rachat de Concrete LCDA, Ober a souhaité rassembler toutes ses marques dans un site unique, structuré autour des deux entreprise : Oberflex et Concrete LCDA.
      </p>
      <p>
        Mais avec plus de 1800 produits, comment présenter clairement un catalogue aussi large et profond ?
        Comment concilier la pédagogie d’une offre hyper technique et la sobriété indispensable pour séduire et inspirer les prescripteurs ?
      </p>
		</ContentList>
    <ImageSingle
        image="Ober_001.jpg"
        boxed="true"
        mask="true"
    />
		<ContentList
			alignText="center"
			:columns=1
			title="Solution"
			id="solution"
		>
      <h3>Une architecture à deux niveaux</h3>
      <br/>
      <p>Niveau global : une home page commune aux deux entreprises, et les fonctions transversales (compte client, panier, gestion des commandes d’échantillons, ainsi qu’une matériauthèque en ligne).</p>

      <p>Niveau entreprise : chaque entreprise dispose de sa propre page d’accueil et son propre menu de navigation,
        ce qui lui permet de fonctionner comme un site indépendant, tout en bénéficiant des fonctions transversales.</p>
      <Button
          label="Visiter le site"
          link="https://www.ober-surfaces.com/"
          target="_blank"
          align="center"
      />
		</ContentList>
    <ImageSingle
			image="Ober_002.jpg"
			parallax="true"
		>
		</ImageSingle>
		<ImageList
      id="contenus"
      alignText="center"
      title="Contenus"
			:imagelistItems="[
          {
            image: 'Ober_003.jpg',
            description: '«Le Mag» permet de partager des conseils de mise en œuvre, témoignages, coulisses, inspiration, environnement…',
            shadowWidth:'2rem'
          },
          {
            image: 'Ober_004.jpg',
            description: 'Afin d’inspirer les prescripteurs, la rubrique «In Situ», plus riche et détaillée, présente les produits en situation dans les projets les plus récents, complétée par la nouvelle rubrique «Designers»',
            shadowWidth:'2rem'
          },
          {
            image: 'Ober_005.jpg',
            description: 'Les liens contextuels entre les rubriques permet d’explorer librement et intuitivement les contenus',
            shadowWidth:'2rem'
          }
      ]"
			:columns=3
      autoHeight="true"
      boxed="true"
      margin="4rem"
      bgColor="#f6f6f6"
		>
		</ImageList>
		<ContentList
			alignText="center"
			:columns=1
			title="Innovation"
			id="innovation"
      style="padding-bottom: 0;"
		/>
		<ImageSingle
			image="Ober_006.jpg"
      boxed="true"
		>
		</ImageSingle>
    <ContentList
        alignText="center"
        :columns=1
        id="apercu"
        style="padding-top: 0;"
    >
      <h3>
        Aperçu des échantillons en taille réelle
      </h3>
      <p>
        Pour la première fois, un site Internet permet de visualiser les produits grandeur nature quelque soit la taille et la résolution de l’écran (ordinateur fixe ou portable, tablette ou mobile). Un petit détail qui fait toute la différence lorsqu’il s’agit de choisir un produit de décoration d’intérieur.
      </p>
    </ContentList>
    <VideoSingle
        video="Ober_video.mp4"
        boxed="true"
        mask="true"
        poster="Ober_poster_video.png"
    />
    <ContentList
        alignText="center"
        :columns=1
        id="materiautheque"
        style="padding-top: 0;"
    >
      <h3>
        La matériauthèque
      </h3>
      <p>
        La matériauthèque permet de rechercher facilement des produits des deux catalogues selon des critères techniques et esthétiques, d’effectuer des sélections et de les visualiser sous forme de planches de matériaux, et de les partager avec le client final
      </p>
    </ContentList>

    <ContentList
        alignText="center"
        :columns=1
        id="services"
        style="padding-bottom: 0;"
    >
      <h3>
        Services aux prescripteurs
      </h3>
    </ContentList>
    <ImageSingle
        image="Ober_007.svg"
        boxed="true"
    />
		<CaseNext
			page="Création affiches"
		>
		</CaseNext>
	</div>
	

</template>

<script>

import CaseTop from '@/components/CaseTop.vue';
import CaseNext from '@/components/CaseNext.vue';
import ContentList from '@/components/ContentList.vue';
import ImageSingle from '@/components/ImageSingle.vue';
import ImageList from '@/components/ImageList.vue';
import VideoSingle from "@/components/VideoSingle.vue";
import Button from "@/components/Button.vue";

export default {
	name: 'CaseOber',
	components: {
    Button,
    VideoSingle,
		CaseTop, ContentList, ImageSingle, CaseNext, ImageList
	},
	data () {
		return {
		}
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";


</style>
