<template>
	<section class="case_next">
		<a
			v-if="page == thisItem.name"
			class="case_next_link no_hover"
			:data-page="thisItem.name" 
			@click.prevent="clickOnNextCase"
		>
			<h2>
				<span>Projet suivant : </span><span v-html="page"></span>
				<br/>
				<font-awesome-icon :icon="['far', 'angle-down']"/>
			</h2>
			<div class="case_next_link_frame" :style="{'background-color': thisItem.color_transition}" v-lazy:background-image="imgPath(thisItem.image_cover)">
			</div>
		</a>

	</section>

</template>

<script>

import { TweenMax, Expo } from "gsap";
import $ from "jquery";
import Vue2Filters from 'vue2-filters';
import Router from "@/router/index.js";

export default {
	name: 'CaseNext',
	mixins: [Vue2Filters.mixin],
	components: {
	},
	data: function(){
		return {
			thisItem: {
				type: Array,
			},
			isItem: (this.thisItem),
			menuItems: {
				type: Array,
			}
		};
	},
	props: {
		page: {
			type: String,
			default: '',
		}
	},

	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
		clickOnNextCase(event){
			let $item = $(event.target).parents(".case_next").find(".case_next_link");
			this.launchNextCase($item);
		},
		launchNextCase($el){
			let $elFrame = $el.find(".case_next_link_frame");
			let $elFrameleftOffset = $elFrame.offset().left - $(document).scrollLeft();
			let $elFrametoptOffset = $elFrame.offset().top - $(document).scrollTop();
			let $elFrameheight = $elFrame.outerHeight();
			let $elFramewidth = $elFrame.outerWidth();
			let $elFrameColor = $elFrame.css("background-color");
			let $elFrameBgImage = $elFrame.css("background-image");
			let $elFrameBgSize = $elFrame.css("background-size");
			let $elFrameBgPosition = $elFrame.css("background-position");

			$("body").append( "<div id='frameTransition'></div>" );
			let $elFrameTransition = $("#frameTransition");
			$elFrameTransition.css({
				"position" : "fixed",
				"width" : $elFramewidth,
				"height" : $elFrameheight,
				"top" : $elFrametoptOffset,
				"left" : $elFrameleftOffset,
				"opacity" : "1",
				"background-color" : $elFrameColor,
				"background-image" : $elFrameBgImage,
				"background-size" : $elFrameBgSize,
				"background-position" : $elFrameBgPosition,
			});

			TweenMax.to(
				$(".app_main_container"),
				1.2,
				{	
					delay: 0.5,
					translateY: -200,
				}
			);

			TweenMax.fromTo(
				$elFrameTransition,
				1.2,
				{
					top:$elFrametoptOffset, 
					left:$elFrameleftOffset, 
					width: $elFramewidth, 
					height: $elFrameheight,
					opacity: "1",
				},
				{
					top: 0, 
					left: 0, 
					width: '100%', 
					height: '100%', 
					ease: Expo.easeInOut,
					onComplete: () => {
						this.$router.push({ 
							name: $el.data('page')
						});
						$elFrameTransition.delay(500).fadeOut( 500, function() {
							$elFrameTransition.remove();
						});
					}
				},
			);
		},
		
	},
	mounted(){
		this.menuItems = Router.options.routes;
		let pageName = this.page;
		let thisItem = this.menuItems.filter( function(menuItem) {
			return (menuItem.name == pageName);
		});

		if (thisItem.length > 0) {
			this.thisItem = thisItem[0];
		}
	},
	destroyed(){
		
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";



.case_next {
	&_link {
		display: block;

		h2 {
			pointer-events: none;
			font-size: 2.4rem;
			text-align: center;
			padding: 5rem 5rem 3.5rem 5rem;

			svg {
				transition: $E-defaultTime/2 ease-out;
			}
		}

		&_frame {
			background-size: cover;
			background-position: top;
			text-align: center;
		}

		&:hover {
			h2 {
				svg {
					padding-top: 0.5rem;
					transition: $E-defaultTime/2 ease-out;
				}
			}
		}
	}
}

</style>
