<template>
	<section class="image_list_section" v-bind:style="{'background-color': bgColor, 'text-align': alignText}" :id="id">
    <h2 v-if="title" v-html="title"></h2>
    <div class="image_list" v-bind:class="{ autoHeight: isAutoHeight, multiline: isMultiline, image_list_boxed: isBoxed}" v-bind:style="{'gap': margin}">
			<div
				v-for="imagelistItem in imagelistItems"
				v-bind:key="imagelistItem.id"
				class="image_list_element"
				v-bind:style="{'width': columnsWidth(columns), 'flex-basis': columnsWidth(columns)}"
				v-lazy:background-image="require('../assets/images/'+imagelistItem.image)"
			>
        <img v-if="isAutoHeight" v-lazy="require('../assets/images/'+imagelistItem.image)" alt="" style="width: 100%" v-bind:style="{'box-shadow': boxShadow(imagelistItem.shadowWidth)}">
				<div v-if="imagelistItem.legende && imagelistItem.darkLegende != 'true'" class="image_section_legende" v-html="imagelistItem.legende"></div>
				<div v-else-if="imagelistItem.legende && imagelistItem.darkLegende == 'true'" class="image_section_legende dark" v-html="imagelistItem.legende"></div>
        <div v-if="imagelistItem.description" v-html="imagelistItem.description" class="image_section_description"></div>
			</div>
		</div>
		<slot></slot>
	</section>

</template>

<script>

import Vue2Filters from 'vue2-filters';

export default {
	name: 'ImageList',
	mixins: [Vue2Filters.mixin],
	components: {
	},
	data: function(){
		return {
      isAutoHeight: (this.autoHeight != "false"),
      isBoxed: (this.boxed),
      isMultiline: (this.imagelistItems.length > this.columns)
		};
	},
	props: {
		imagelistItems: {
			type: Array,
		},
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
		image: {
			type: String,
			default: '',
		},
    columns: {
      type: Number,
      default: 1,
    },
    margin: {
      type: String,
      default: "",
    },
    autoHeight: {
      type: String,
      default: "false",
    },
    boxed: {
      type: String,
      default: "",
    },
    legende: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: "",
    },
    darkLegende: {
      type: String,
      default: "",
    },
    shadowWidth: {
      type: String,
      default: "0",
    },
    alignText: {
      type: String,
      default: 'left',
    },
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
    columnsWidth: function(columns){
      columns = 100 / columns;
      return columns+'%';
    },
    boxShadow: function(shadowWidth){
      if(shadowWidth) {
        return "0px 0px "+shadowWidth+" rgba(0,0,0,0.05)";
      }
      else {
        return "none;"
      }
    },
	},
	mounted(){
		console.log(this.columns);
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.image_list_section {
  padding: 5rem;
  h2 {
    font-family: $IsidoraBold;
    font-size: 4rem;
    position: relative;
    display: inline-block;
    margin-bottom: 4rem;

    &:after {
      content: "";
      position: absolute;
      bottom: -1.5rem;
      height: 0.4rem;
      width: 8rem;
      background-color: $black;
      left: 50%;
      transform: translateX(-50%);
    }
  }

	.image_list {
		display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
		height: auto;
    align-content: flex-start;
    &_boxed {
      text-align: center;
      max-width: 160rem;
      margin: auto;
      @include breakpoint(lt-sm) {
        padding: 0 3.5rem;
      }
    }
    &.multiline {
      flex-wrap: wrap;
    }

		@include breakpoint (lt-sm){
			flex-direction: column;
		}

    .image_list_element {
      height: auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      @include breakpoint(lt-sm){
        width: 100% !important;
        flex-basis: 100% !important;
      }

      .image_section_description {
        padding-top: 4rem;
        font-size: 2rem;
      }
    }

    &.autoHeight {
      .image_list_element {
        background-image: none !important;
      }
    }

    &:not(.autoHeight) {
      .image_list_element {
        height: 60vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;

        @include breakpoint(lt-md) {
          height: 30vh;
        }

        @include breakpoint(lt-sm) {
          flex: unset;
          height: calc(900vw / 16);
        }

        .image_section_legende {
          position: absolute;
          right: 0;
          bottom: 0;
          padding: $padding-simple;
          background-color: transparent;
          color: $white;
          transition: $E-defaultTime $E-default;

          &.dark {
            color: $black;
          }
        }

        &:hover {
          .image_section_legende {
            background-color: rgba(0, 0, 0, 0.5);
            color: $white;
            transition: $E-defaultTime $E-default;
          }
        }
      }
		}
	}
}

.case {
  .image_list_section {
    h2 {
      font-size: 2.8rem;
      color: $black;
      margin-bottom: 4rem;

      &:after {
        display: none;
      }
    }
  }
}

</style>
