<template>
	<section 
		class="case_top"
		v-bind:class="{lightTheme: isLight}"
	>
		<div class="case_top_title">
			<h1 v-html="title"></h1>
			<h2 v-html="subTitle"></h2>
		</div>
		<div class="case_top_image"
			:style="{'background-image': 'url(' + imgPath(cover) + ')'}"
		>
		</div>

		<Scrolldown @click.native="scrollDown()"></Scrolldown>
	</section>

</template>

<script>

import $ from "jquery";
import Vue2Filters from 'vue2-filters';
import Scrolldown from "@/components/Scrolldown";
import Verge from 'verge';
import { gsap } from "gsap/all";

export default {
	name: 'CaseTop',
	mixins: [Vue2Filters.mixin],
	components: {
		Scrolldown
	},
	data: function(){
		return {
			isLight: (this.light == "true"),
		};
	},
	props: {
		cover: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
		light: {
			type: String,
			default: "true",
		},
		protectHeader: {
			type: String,
			default: "false",
		},
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
		scrollDown: function(){
			$("#app").removeClass("noscroll");
			let h;
			if (!$("#app").hasClass("noSticky")) {
				h = Verge.viewportH() - $("header").outerHeight();
			}
			else {
				h = Verge.viewportH();
			} 
			gsap.fromTo(
				$(".scrolldown"),
				0.25,
				{translateY: 0, opacity: 1},
				{translateY: 50, opacity: 0, ease: gsap.easeInOut, onComplete: () => {
					gsap.to(document.documentElement, 1, {scrollTop: h});
				}},
			);
		},
		animateTitle: function(){
			gsap.fromTo(
				$(".case_top_title"),
				0.75,
				{translateX: -250, opacity: 0},
				{translateX: 0, opacity: 1, ease: gsap.easeInOut},
			);
		},
		mouseWheel: function(){
			$('#app').bind('mousewheel', function(e){
				if($("#app").hasClass("noscroll")){
					$("#app").removeClass("noscroll")
					gsap.fromTo(
						$(".scrolldown"),
						0.25,
						{translateY: 0, opacity: 1},
						{translateY: 50, opacity: 0, ease: gsap.easeInOut},
					);
				}
			});
		}
	},
	mounted(){
		$("#app").addClass("noscroll");
		$("#app").addClass("transparentHeader");
		$("#app").addClass("case");
		$("#app").addClass("case");

		if (this.light == "true") {
			$("#app").addClass("lightHeader");
		}

		if (this.protectHeader == "true") {
			$("#app").removeClass("lightHeader");
			$("#app").addClass("protectedHeader");
		}

		setTimeout(() => {
			this.animateTitle();
		}, 1000);

		this.mouseWheel();
	},
	destroyed(){
		$("#app").removeClass("noscroll transparentHeader lightHeader protectedHeader");
		$("#app").removeClass("case");
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.case_top {
	height: 100vh;

	&_title {
		position: absolute;
		color: #ffffff;
		top: calc(100% / 3);
		left: $padding-double;
		transform: translateY(-50%);
		width: 40%;
		opacity: 0;

		@include breakpoint (lt-md) {
			left: 1.5rem;
		}

		@include breakpoint (lt-sm) {
			width: 80%;
		}

		h1 {
			font-size: 6rem;
			font-family: $IsidoraBold;
			line-height: 1em;
		}

		h2 {
			font-size: 2rem;
			font-family: $IsidoraLight;
			padding-top: 4rem;
			position: absolute;
		}
	}

	&_image {
		height: 100%;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		@include breakpoint (lt-md) {
			background-position: left center;
			background-attachment: initial;
		}
	}
}

</style>
