<template>
	<div class="home">
		<vue-headful
			title="Slumberland : agence de design graphique et digital"
			description="Slumberland est une agence de création graphique et digitale basée à Paris. Elle est fondée, gérée et développée par 3 créatifs associés. Nous accompagnons les entreprises - de la startup aux grands comptes - pour créer des plateformes de communication créatives et pertinentes."
		/>
		<RealisationSection
			:realisationItems="realisationFocusItems"
			class="focus_section"
		></RealisationSection>
		<RealisationSection
			:realisationItems="realisationItems"
			class="normal_section"
		></RealisationSection>
	</div>
</template>

<script>

import RealisationSection from "@/components/RealisationSection"

export default {
	name: "Home",
	components: {
		RealisationSection
	},
	data () {
		return {
			title: "Realisation Section",
			realisationFocusItems: [
				{
					id: 0,
					page: "Festival du cinéma américain de Deauville",
					description: "Création identité visuelle, charte graphique et conception des affiches <br/>Pour le Public Système Cinéma",
					image: "card_deauville2020.png",
					focus: true
				},
				{
					id: 1,
					titre: "Dassault Falcon",
					description: "Conception et réalisation du site internet Dassault&nbsp;Falcon",
          lien: "https://dassaultfalcon.com",
          lien_texte: "Site Dassault Falcon",
					image: "card_Dassault.jpg",
				},
				{
					id: 2,
					page: "Sonance audition",
					description: "Design 360°",
					image: "card_sonance.jpg"
				},
			],
			realisationItems: [
				{
					id: 0,
					page: "Création affiches",
					lien_texte: "Découvrir les affiches",
					image: "card_affiches.jpg",
				},
				{
					id: 1,
					titre: "Savoie Mont Blanc",
					description: "Création marque, architecture de marque et charte graphique <br/>Pour l’agence Leon Travel & Tourism",
					lien_texte: "Détails à venir",
					image: "card_savoiemb.jpg"
				},
				{
					id: 2,
					page: "Société Générale",
					description: "Conception et réalisation de 3 usines à sites",
					image: "card_SG.svg"
				},
				{
					id: 4,
					titre: "IFCIC",
					description: "Création marque, territoire de style et édition",
					lien_texte: "Détails à venir",
					image: "card_ifcic.jpg"
				},
				{
					id: 6,
					titre: "Coin de Mire Cinéma",
					description: "Création identité visuelle, création territoire de style et conception graphique série coffret",
					lien_texte: "Détails à venir",
					image: "card_coindemire.jpg"
				},
				{
					id: 7,
					page: "Groupe Ober",
					description: "Conception et réalisation des sites internet du groupe Ober",
					image: "card_ober.jpg"
				},
			],
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.home {
	padding-bottom: $padding-simple;
	margin: $padding-simple;

	.focus_section {
		margin-top: $padding-simple-neg;
	}

}

</style>
