<template>
	<div class="header_inner_right_content">
		<div 
			class="hamburger_menu"
			v-on:click="activeHamburgerMenu"
			>
			<div class="hamburger_button">
				<span></span>
				<span></span>
				<span></span>
			</div>
			<div class="cross_button">
				<span></span>
				<span></span>
			</div>
		</div>
		<div class="main_menu">
			<menu>
				<menuitem 
				v-for="menuItem in orderBy(menuItems,'id')" 
				v-bind:key="menuItem.id">
					<router-link v-if="menuItem.path" :to="{ name: menuItem.name}">
						{{menuItem.name}}
					</router-link>
					<a v-if="menuItem.url" :href="menuItem.url">
						{{menuItem.name}}
					</a>
					<a v-if="menuItem.contactMail" class="contact_mail">
						{{menuItem.name}}
					</a>
				</menuitem>
			</menu>
			<div class="activedBar"></div>
		</div>
		<div class="social_menu">
			<menu>
				<menuitem 
				v-for="menuSocialItem in menuSocialItems"
				v-bind:key="menuSocialItem.id">
					<a :href="menuSocialItem.path" target="_blank">
						<img class="logo_black" :src="imgPathSocialBlack(menuSocialItem.media)"/>
						<img class="logo_white" :src="imgPathSocialWhite(menuSocialItem.media)"/>
					</a>
				</menuitem>
			</menu>
		</div>
	</div>
</template>

<script>

import $ from "jquery";
import Vue2Filters from 'vue2-filters';

export default {
	name: "Menu",
	mixins: [Vue2Filters.mixin],
	data () {
		return {
			title: "Menu",
			menuItems: [
				{
					id: 0,
					name: "Réalisations",
					path:"/"
				},
				{
					id: 1,
					name: "L’agence",
					path:"/agence"
				},
				{
					id: 2,
					name: "Contact",
					contactMail: true
				},
			],
			menuSocialItems: [
				{
					id: 0,
					media: "insta",
					path:"https://www.instagram.com/slumberland.design/"
				},
				{
					id: 1,
					media: "linkedin",
					path:"https://linkedin.com/company/slumberland-design"
				}
			],
		}
	},

	methods: {
		imgPath: function(image){
			return require("../assets/images/"+image);
		},
		imgPathSocialBlack: function(media) {
			return require("../assets/images/"+media+"_b.svg");
		},
		imgPathSocialWhite: function(media) {
			return require("../assets/images/"+media+"_w.svg");
		},
		activeHamburgerMenu: function(){
			$("body").toggleClass("open_menu");
		}
	},

	mounted () {
	},

	created () {
	},

	destroyed () {
	}
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/main.scss";


</style>
