<template>
	<div class="not_found">
		<vue-headful
			title="Slumberland : 404"
			description=""
		/>
		<h1>404 - Page introuvable</h1>
		<p class="text-center">
			Nous sommes désolés, cette page n'est pas disponible.
		</p>
		<p class="text-center">
			<router-link :to="{ name: 'Réalisations'}">
				Retour à la liste de nos réalisations <font-awesome-icon :icon="['far', 'arrow-right']"/>
			</router-link>
		</p>
		<p class="text-center">
			<router-link :to="{ name: 'L’agence'}">
				Découvrir l'agence <font-awesome-icon :icon="['far', 'arrow-right']"/>
			</router-link>
		</p>
	</div>
</template>

<script>

export default {
	name: "NotFound"
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";
.not_found {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1 {
		font-size: 4rem;
		font-family: $IsidoraBold;
		font-size: 4rem;
		position: relative;
		display: inline-block;
		margin-bottom: 4rem;
		&:after {
			content: "";
			position: absolute;
			bottom: -1.5rem;
			height: 0.4rem;
			width: 8rem;
			background-color: $black;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	p {
		width: auto;
		a {
			svg {
				padding-left: 1rem;
			}
		}
	}
}

</style>
