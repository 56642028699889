<template>
	<div class="element_container" v-bind:class="{ focus: isFocus }">
		<div class="element_container-inner">
		<img :src="imgPath(image)"  />
			<div class="element_hover_container-inner">
				<div class="element_hover_content" :data-transitionColor="thisItem.color_transition">
					<div class="element_hover_title">
						<h2 v-if="page" v-html="thisItem.name">
						</h2>
						<h2 v-else v-html="titre">
						</h2>
					</div>
					<div class="element_hover_description">
						<h3 v-if="description" v-html="description">
						</h3>
						<div class="element_hover_link">
							<div v-if="lien_texte" class="element_hover_link_icon">
								<font-awesome-icon v-if="page" :icon="['far', 'angle-right']" size="lg" />
								<font-awesome-icon v-else-if="lien && lien_texte || lien_liste" :icon="['far', 'external-link']" size="lg" />
								<font-awesome-icon v-else-if="!lien && lien_texte" :icon="['far', 'hourglass']" size="lg" />
							</div>
							<a v-if="lien && !lien_liste" :href="lien" target="_blank" v-html="lien_texte">
							</a>
							<a v-else-if="lien_liste" class="randomlink project_external_link" target="_blank" :href="random_url(jsonPath(lien_liste))" v-on:click="random_url_click($event, jsonPath(lien_liste))" v-html="lien_texte"></a>
							<a
								v-else-if="page" 
								class="menu_item"
								:data-page="thisItem.name" 
								v-html="lien_texte" 
								@click.prevent="clickOnRealisationItem"
							></a>
							<span v-else v-html="lien_texte">
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {TweenMax, Expo} from 'gsap';
import $ from "jquery";
import Router from "@/router/index.js";

export default {
	name: 'Realisation',
	props: {
		titre: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		lien: {
			type: String,
			default: '',
		},
		page: {
			type: String,
			default: '',
		},
		lien_texte: {
			type: String,
			default: 'Découvrir',
		},
		lien_liste: {
			type: String,
			default: '',
		},
		focus: {
			type: Boolean,
			default: false,
		},
		image: {
			type: String,
			default: 'default.jpg',
		},
		color_transition: {
			type: String,
			default: '#df0613',
		},
	},
	data: function(){
		return {
			isFocus: (this.focus == true),
			thisItem: {
				type: Array,
			},
			isItem: (this.thisItem),
			menuItems: {
				type: Array,
			}
		};
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
		jsonPath: function(json){
			return require('../assets/json/'+json+".json");
		},
		random_url(linkList) {
			let $array = Array( linkList);
			$array = $array[0];
			let $random_selected = $array[Math.floor(Math.random() * $array.length)];
			let $random_selected_str = Object.values($random_selected)[0];
			return $random_selected_str;
		},
		random_url_click($event, linkList) {
			let $this = $event.target;
			let $array = Array( linkList);
			$array = $array[0];
			let $random_selected = $array[Math.floor(Math.random() * $array.length)];
			let $random_selected_str = Object.values($random_selected)[0];
			$this.setAttribute("href", $random_selected_str);
		},
		clickOnRealisationItem(event){
			let $item = $(event.target);
			this.launchIntroRealisation($item);
		},
		launchIntroRealisation($el){
			let $elFrame = $el.parents(".element_hover_content");
			let $elFrameleftOffset = $elFrame.offset().left - $(document).scrollLeft();
			let $elFrametoptOffset = $elFrame.offset().top - $(document).scrollTop();
			let $elFrameheight = $elFrame.outerHeight();
			let $elFramewidth = $elFrame.outerWidth();
			let $elFrameColor = $elFrame.css("background-color");
			let $elFrameColorTransition = $elFrame.attr("data-transitionColor");
			
			$("body").append( "<div id='frameTransition'></div>" );
			let $elFrameTransition = $("#frameTransition");
			$elFrameTransition.css({
				"position" : "fixed",
				"width" : $elFramewidth,
				"height" : $elFrameheight,
				"top" : $elFrametoptOffset,
				"left" : $elFrameleftOffset,
				"opacity" : "1",
				"background" : $elFrameColor,
			});

			TweenMax.fromTo(
				$elFrameTransition,
				1.2,
				{
					top:$elFrametoptOffset, 
					left:$elFrameleftOffset, 
					width: $elFramewidth, 
					height: $elFrameheight,
					opacity: "1",
					background: $elFrameColor
				},
				{
					top: 0, 
					left: 0, 
					width: '100%', 
					height: '100%', 
					background: $elFrameColorTransition, 
					ease: Expo.easeInOut, 
					onComplete: () => {
						this.$router.push({ 
							name: $el.data('page')
						});
						$elFrameTransition.delay(500).fadeOut( 500, function() {
							$elFrameTransition.remove();
						});
					}
				},
			);
		},
	},
	mounted(){
		this.menuItems = Router.options.routes;
		let pageName = this.page;
		let thisItem = this.menuItems.filter( function(menuItem) {
			return (menuItem.name == pageName);
		});

		if (thisItem.length > 0) {
			this.thisItem = thisItem[0];
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/main.scss";


.element_container {
	width: calc(100% / 3);
	padding: $padding-simple;
	box-sizing: border-box;
	overflow: hidden;
	color: $white;

	@include breakpoint(lt-md){
		width: calc(100% / 2);
	}

	@include breakpoint(lt-sm){
		width: 100%;
	}

	.element_container-inner {
		position: relative;
		overflow: hidden;
		background-color: transparent;

		img {
			width: 100%;
			display: flex;
			height: 100%;
		}

		.element_hover_container-inner {
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.5s ease-out;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			box-sizing: border-box;

			.element_hover_content {
				position: relative;
				background: $red;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				text-align: center;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				padding: 5rem;

				.element_hover_title {
					font-size: 2rem;
					font-family: $IsidoraSemiBold;
					font-weight: normal;
					line-height: 1.2em;
					padding-bottom: 0.25rem;
					opacity: 0;
					transform: translateY(2rem);
					transition: 0.5s ease-out 0.5s;
				}

				.element_hover_description {
					padding-top: 0.1rem;
					opacity: 0;
					transform: translateY(2rem);
					transition: 0.5s ease-out 0.5s;
					max-width: 75%;

					h3 {
						font-size: 1.6rem;
					}

					.element_hover_link {
						padding-top: 1.5rem;
						display: flex;
						justify-content: center;

						&_icon {
							line-height: 1.5rem;
							padding-right: 1rem;
						}

						&:hover {

						}	

						img {
							height: 1.5rem;
							transform: none;
							padding-right: 0.5rem;
							width: auto;
						}
						a,
						span {
							color: $white;
							font-size: 1.4rem;
							line-height: 1.5rem;
							vertical-align: text-bottom;
						}
						a {
							pointer-events: none;
						}
					}
				}
			}
		}

		&:hover,
		&.active {

			.element_hover_container-inner {
				opacity: 1;
				pointer-events: initial;
				transition: opacity 0.35s ease-out;

				.element_hover_title {
					opacity: 1;
					transform: translateY(0);
					transition: 0.35s ease-out 0.25s;
				}

				.element_hover_description {
					opacity: 1;
					transform: translateY(0);
					transition: 0.35s ease-out 0.35s;
					.element_hover_link {
						a {
							pointer-events: initial;
						}
					}
				}
			}
		}
	}
	&.focus {
		width: calc(100% * 2/3);
		max-height: calc(100% - #{$padding-simple});

		@include breakpoint(lt-md){
			width: 100%;
			height: auto;
		}

		.element_container-inner {
			height: auto;
			overflow: hidden;

			img {
				position: relative;
				top: 0;
				transform: translateY(0);
			}
		}
	}
}

</style>
