import Vue from 'vue'
import Router from 'vue-router'

/* Pages */
import Home from '@/pages/Home'
import NotFound from '@/pages/NotFound'
import Agence from '@/pages/Agence'
import Mentions from '@/pages/Mentions'

/* Cas clients */
import Deauville from '@/cases/Deauville'
import Affiches from '@/cases/Affiches'
import Sonance from '@/cases/Sonance'
import SG from '@/cases/SG'
import Ober from '@/cases/Ober'

/* Google Tag Manager */

import VueGtm from 'vue-gtm';

Vue.use(Router)

Vue.use(VueGtm, {
  id: 'GTM-PH4PP2F', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 
});

/* SVG */

import {InlineSvgPlugin} from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);

export default new Router({
  mode: 'history',
  // Change base value.
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'Réalisations',
      component: Home
    },
    {
      path: '/404',
      name: '404',
      component: NotFound
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/agence',
      name: 'L’agence',
      component: Agence
    },
    {
      path: '/mentions-legales',
      name: 'Mentions légales',
      component: Mentions
    },
    {
      path: '/festival-cinema-americain-deauville',
      name: 'Festival du cinéma américain de Deauville',
      component: Deauville,
      image_cover: "Deauville_cover.jpg",
      color_transition: "#ce9442"
    },
    {
      path: '/affiches',
      name: 'Création affiches',
      image_cover: "Affiches_cover.jpg",
      component: Affiches
    },
    {
      path: '/sonance-audition',
      name: 'Sonance audition',
      image_cover: "Sonance_cover.jpg",
      component: Sonance,
      color_transition: "#25afff"
    },
    {
      path: '/societe-generale',
      name: 'Société Générale',
      image_cover: "SG_cover.jpg",
      component: SG,
      color_transition: "#a8c1e2"
    },
    {
      path: '/groupe-ober',
      name: 'Groupe Ober',
      image_cover: "Ober_cover.jpg",
      component: Ober,
      color_transition: "#E5B883"
    },
  ]
})

