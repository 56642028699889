<template>
	<div class="mentions">
		<vue-headful
			title="Slumberland | Mentions légales"
			description=""
		/>
	<ContentList
		:contentlistItems="mentionsItems"
		alignText="center"
		title="Mentions légales"
		id="mentions"
	/>
	<ContentList
		:contentlistItems="creditsItems"
		alignText="center"
		title="Crédits"
		id="credits"
	/>

	</div>
</template>

<script>

import ContentList from '@/components/ContentList'

export default {
	name: 'Mentions',
	components: {
		ContentList
	},
	data () {
		return {
			creditsItems: [
				{
					titre: "Photographies agence : Vincent Bourdon",
				},
			],
			mentionsItems: [
				{
					titre: "Raison sociale : Slumberland",
				},
				{
					titre: "Forme juridique : SARL",
				},

				{
					titre: "Adresse : 59 Rue du Faubourg Saint-Antoine, 75011 Paris",
				},

				{
					titre: "Capital social : 7 500,00 €",
				},

				{
					titre: "Adresse de courrier électronique : <a class='contact_mail'>contact@slumberland.design</a>",
				},

				{
					titre: "Numéro de téléphone : 01 86 95 50 60",
				},

				{
					titre: "RCS : 433 677 416 00029",
				},

				{
					titre: "TVA intracommunautaire : FR 69 433677416",
				},

				{
					titre: "Directeur de la publication : Guillaume Tirard",
				},

				{
					titre: "Hébergeur du site : OVH 2 rue Kellerman BP 80157 59100 ROUBAIX",
				},
			],
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";



</style>
