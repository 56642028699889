<template>
	<div class="list_content_element">
		<div v-if="image" class="list_content_element_image">
			<img :src="imgPath(image)"/>
		</div>
		<div v-if="titre" class="list_content_element_titre" v-html="titre"></div>
		<div v-if="name" class="list_content_element_name" v-html="name"></div>
		<div v-if="fonction" class="list_content_element_fonction" v-html="fonction"></div>
		<div v-if="associe" class="list_content_element_associe" v-html="associe"></div>
	</div>
</template>

<script>

export default {
	name: 'ContentListElement',
	components: {
	},
	props: {
		contentlistItems: {
			type: Array,
		},
		titre: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		fonction: {
			type: String,
			default: '',
		},
		associe: {
			type: String,
			default: '',
		},
		image: {
			type: String,
			default: '',
		} 
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
	},
	mounted(){
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.list_content_element {
	line-height: 1.3em;

	@include breakpoint (lt-sm) {
		width: 100% !important;
	}

	&_titre {
		display: inline;
	}

	&_name {
		font-family: $IsidoraSemiBold;
	}

	&_image {
		border-radius:50%;
		box-sizing: border-box;
		width: 25rem;
		height: 25rem;
		overflow: hidden;
		margin: 3rem auto 2rem auto;

		img {
			position: relative;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 100%;
		}
	}

	&_fonction, &_associe {
		font-family: $IsidoraLight;
	}
}

</style>
