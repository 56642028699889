<template>
  <div class="button_container" v-bind:style="{'justify-content': align}">
    <a :href="link" :target="target" class="button_element" v-bind:style="{'color': color}">
      <font-awesome-icon v-if="picto && !pictoAfter" :icon="[pictoWeight, picto]"/>
      <span v-html="label"></span>
      <font-awesome-icon v-if="picto && pictoAfter" :icon="[pictoWeight, picto]"/>
    </a>
  </div>

</template>

<script>

import Vue2Filters from 'vue2-filters';

export default {
  name: 'Button',
  mixins: [Vue2Filters.mixin],
  components: {
  },
  data: function(){
    return {
      isOutline: (this.outline),
    };
  },
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    label: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    picto: {
      type: String,
      default: '',
    },
    pictoWeight: {
      type: String,
      default: 'far',
    },
    pictoAfter: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'flex-start',
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";
.button {
  &_container {
    display: flex;
    width: 100%;
    padding-top: 2rem;
  }
  &_element {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 1rem;
    color: currentColor;
    border: 1px solid currentColor;
    font-size: 1.8rem;
    padding: 1rem 2rem;
    transition: 0.25s;
    &:hover {
      background-color: #000000 !important;
      color: #ffffff !important;
      border-color: #000000 !important;
      transition: 0.25s;
    }
    i {

    }
  }
}

</style>
