<template>
	<div class="agence">
		<vue-headful
			title="Slumberland | L'agence"
			description="Slumberland s’engage à réveler votre marque, à faire rayonner votre communication et à ré-enchanter votre présence digitale."
		/>
	
		<!-- Content section -->
		<section class="content_section header_section">

			<div class="baseline">
				“Le pays des rêves”
			</div>

			<div class="intro">
				Slumberland s’engage à réveler votre marque, à faire rayonner votre communication et à ré-enchanter votre présence digitale. 
			</div>

		</section>
		<!-- /Content section -->

		<!-- Content section -->
		<ContentList
			alignText="center"
			:columns=1
			title="Qui sommes nous"
			id="about"
		>
			<p>
				Slumberland est une agence de création graphique et digitale basée à Paris. Elle est fondée, gérée et développée par 3 créatifs associés. Nous accompagnons les entreprises - de la startup aux grands comptes - pour créer des plateformes de communication créatives et pertinentes.
			</p>
			<p>
				Crée en 2009 sous le nom Les Quatre Lunes Digitales, l’agence devient Slumberland en 2016.
			</p>
			
		</ContentList>
		<!-- /Content section -->

		<!-- Content section -->
		<ContentList
			:contentlistItems="servicesItems"
			alignText="center"
			:columns=1
			title="Services"
			id="services"
			image="agence_services.jpg"
			imageClass="hidden-md"
			parallax=true
		/>
		<!-- /Content section -->

		<!-- Content section -->
		<ContentList
			:contentlistItems="clientsItems"
			alignText="center"
			:columns=1
			title="Clients"
			id="clients"
			orderlistItem="titre"
			inline=true
		/>
		<!-- /Content section -->

		<!-- Image section -->
		<ImageList
			:imagelistItems="agenceImageItems"
			class="hidden-md"
		/>
		<!-- /Image section -->

		<!-- Content section -->
		<ContentList
			alignText="center"
			title="L'équipe"
			id="teamTitle"
		/>
		<!-- /Content section -->

		<!-- Content section -->
		<ContentList
			:contentlistItems="equipeItems"
			alignText="center"
			:columns=3
			id="team"
			bgColor="#ffdd00"
			headTitle=true
		/>
		<!-- /Content section -->

	</div>
</template>

<script>

import ContentList from '@/components/ContentList';
import ImageList from '@/components/ImageList';

export default {
	name: 'Agence',
	components: {
		ContentList, ImageList,
	},
	data () {
		return {
			servicesItems: [
				{
					titre: "Stratégie de marque",
				},
				{
					titre: "Identité visuelle",
				},
				{
					titre: "Territoire de marque",
				},
				{
					titre: "Territoire de communication",
				},
				{
					titre: "Stratégie digitale, stratégie multi-sites",
				},
				{
					titre: "UX",
				},
				{
					titre: "Web design",
				},
				{
					titre: "Développement",
				},
				{
					titre: "Brand content",
				},
				{
					titre: "E-Marketing",
				},
			],
			clientsItems: [
				{
					titre: "ABCDESIGN",
				},
				{
					titre: "ACBB",
				},
				{
					titre: "Action Transfert",
				},
				{
					titre: "Alpi",
				},
				{
					titre: "Atlantis TV",
				},
				{
					titre: "Bleuforêt",
				},
				{
					titre: "BNP Paribas Cash Management",
				},
				{
					titre: "Capiel",
				},
				{
					titre: "CFAO",
				},
				{
					titre: "Coin de Mire Cinéma",
				},
				{
					titre: "CSTB",
				},
				{
					titre: "Danone",
				},
				{
					titre: "Dassault Aviation",
				},
				{
					titre: "Duralex",
				},
				{
					titre: "Eclair digital",
				},
				{
					titre: "Eurapharma",
				},
				{
					titre: "Fédération Française d’Athlétisme",
				},
				{
					titre: "Festival America",
				},
				{
					titre: "Festival Au delà de l’écran",
				},
				{
					titre: "Festival Nuits de Champagne",
				},
				{
					titre: "Gimélec",
				},
				{
					titre: "Humanis",
				},
				{
					titre: "IFCIC",
				},
				{
					titre: "Le Public Système Cinéma",
				},
				{
					titre: "LEON Travel & tourism",
				},
				{
					titre: "Maison Francis Kurkdjian",
				},
				{
					titre: "Ober Surfaces",
				},
				{
					titre: "Over The Moon",
				},
				{
					titre: "Run for you",
				},
				{
					titre: "Salut Ô production",
				},
				{
					titre: "SDDEA",
				},
				{
					titre: "Société Générale",
				},
				{
					titre: "Sonance Audition",
				},
				{
					titre: "Tricotage des Vosges",
				},
				{
					titre: "Ubisoft",
				},
				{
					titre: "UGC Pro",
				},
				{
					titre: "Universal Music",
				},
				{
					titre: "Ville de Vincennes",
				},
			],
			equipeItems: [
				{
					name: "Guillaume Tirard",
					fonction: "Directeur de projet digital",
					associe: "Associé",
					image: "gtirard.jpg",
				},
				{
					name: "Lorène Bruant",
					fonction: "Direction artistique",
					associe: "Associée",
					image: "lbruant.jpg",
				},
				{
					name: "Santiago Bordils",
					fonction: "Direction artistique",
					associe: "Associé",
					image: "sbordils.jpg",
				},
				{
					name: "Kilian Sadrin",
					fonction: "Chef de projet digital",
					image: "ksadrin.jpg",
				},
				{
					name: "Charlie Alignan",
					fonction: "Chef de projet digital",
					image: "calignan.jpg",
				},
			],
			agenceImageItems: [
				{
					image: "agence_image_001.jpg",
				},
				{
					image: "agence_image_002.jpg",
				},
			],
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";


.content_section {

	@include breakpoint(lt-sm) {
		padding: 3.5rem;
	}

	h2 {
		font-family: $IsidoraBold;
		font-size: 4rem;
		position: relative;
		display: inline-block;
		margin-bottom: 6rem;

		&:after {
			content: "";
			position: absolute;
			bottom: -2rem;
			height: 2px;
			width: 8rem;
			background-color: $black;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.header_section {
		background-color: $yellow;
		color: $black;
		padding: 3.5vw 8vw 6vw 8vw;
		margin-top: $padding-simple;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include breakpoint(lt-md) {
			margin-left: calc(-100vw / 80);
			margin-right: calc(-100vw / 80);
		}

		.baseline {
			font-family: $IsidoraItalic;
			font-size: calc(7vw / 2.94);
			padding-bottom: 2vw;

			@include breakpoint(lt-sm) {
				font-size: 1.8rem;
			}

			@include breakpoint(xxl) {
				font-size: 4rem;
			}
		}

		.intro {
			font-family: $IsidoraBold;
			font-size: calc(7vw / 1.68);
			max-width: 126rem;

			@include breakpoint(lt-sm) {
				font-size: 3rem;
			}

			@include breakpoint(xxl) {
				font-size: 7rem;
			}
		}
	}

	&#teamTitle {
		padding-top: 6rem;
		padding-bottom: 3rem;
	}
}

</style>
