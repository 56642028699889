<template>
	<div id="app">
		<Header/>
			<router-view class="app_main_container"/>
		<Footer/>
	</div>
</template>

<script>
		
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import $ from "jquery";

export default {
	name: 'app',
	pages: {
	},
	components: { 
		Header, Footer
	},
	methods: {
		encryptMail: function(){
			$("a.contact_mail").click(function (e) {
				let $mailto_href = $(this).attr("href");

				if (!$mailto_href) {
					var secret = function () {
						return atob('Y29udGFjdEBzbHVtYmVybGFuZC5kZXNpZ24=') 
					};
					$mailto_href = $(this).attr("href", 'mailto:' + secret());
					window.location.href = 'mailto:' + secret();
					e.preventDefault();
				}

				$(this).removeClass("contact_mail");
			});

		},
		activeMenuItem: function () {
			if ($(".main_menu menuitem .router-link-exact-active").length) {
				let $leftOffset = $(".main_menu menuitem .router-link-exact-active").offset().left;
				let $toptOffset = $(".main_menu menuitem .router-link-exact-active").position().top;
				let $height = $(".main_menu menuitem .router-link-exact-active").height();
				let $width = $(".main_menu menuitem .router-link-exact-active").width();
				$("body").removeClass("open_menu");
				$(".main_menu .activedBar").css(
					{
						'left': $leftOffset + $width / 4,
						'top': $toptOffset + $height / 2 + 20,
						'width': $width / 2,
					}
				);
			}
			else {
				let $width = $(".main_menu .activedBar").width();
				let $leftOffset = $(".main_menu .activedBar").offset().left;
				$(".main_menu .activedBar").css(
					{
						'left': $leftOffset + $width / 2,
						'width': 0,
					}
				);
			}
		},
	},	

	updated () {
		this.activeMenuItem();
		this.encryptMail();
	}, 

	mounted () {
		this.encryptMail();

		setTimeout(function () {
			this.activeMenuItem();
		}.bind(this), 500);
	},

	created () {
		window.addEventListener('resize', this.activeMenuItem);
	},

	destroyed () {
		window.removeEventListener('resize', this.activeMenuItem);
	}
}

</script>

<style scoped lang="scss">
@import "assets/scss/main.scss";

#app {
	
	margin-top: $headerHeight;

	&.noscroll {
		height: $viewportheaderHeight;

		&.transparentHeader {
			height: 100vh;
		}
	}

	&.transparentHeader {
		margin-top: 0;
	}

	&.lightHeader {

	}
}

</style>