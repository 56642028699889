<template>
	<div class="Affiches_case">
		<vue-headful
			title="Slumberland | Créations affiches"
			description=""
		/>
		<CaseTop
			title="Créations affiches"
			cover="Affiches_cover.jpg"
			protectHeader="true"
		/>
		<ImageSingle
			image="Affiches_001.jpg"
			bgColor="#2e338d"
			affiche="true"
			legende="Pour l’association Au&#8209;delà&nbsp;de&nbsp;l’Écran et la Marie de Vincennes"
			firstAffiche="true"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_002.jpg"
			bgColor="#ffffff"
			affiche="true"
			light="false"
			legende="Pour le Public Système Cinéma"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_003.jpg"
			bgColor="#162567"
			affiche="true"
			legende="Pour le Public Système Cinéma"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_004.jpg"
			bgColor="#000000"
			affiche="true"
			legende="Pour le Public Système Cinéma"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_005.jpg"
			bgColor="#df0613"
			affiche="true"
			legende="Pour le Public Système Cinéma"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_006.jpg"
			bgColor="#feca30"
			affiche="true"
			light="false"
			legende="Pour le Public Système Cinéma"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_007.jpg"
			bgColor="#00aab5"
			affiche="true"
			legende="Pour Coin de Mire Cinéma"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_008.jpg"
			bgColor="#000000"
			affiche="true"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_009.jpg"
			bgColor="#00aab5"
			affiche="true"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_010.jpg"
			bgColor="#feca30"
			affiche="true"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_011.jpg"
			bgColor="#ffffff"
			affiche="true"
			light="false"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_012.jpg"
			bgColor="#000000"
			affiche="true"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_013.jpg"
			bgColor="#feca30"
			affiche="true"
			light="false"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_014.jpg"
			bgColor="#2e338d"
			affiche="true"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_015.jpg"
			bgColor="#feca30"
			affiche="true"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_016.jpg"
			bgColor="#162567"
			affiche="true"
			legende="Pour e-cinema.com"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_017.jpg"
			bgColor="#000000"
			affiche="true"
			legende="Pour Salut Ô Productions"
		>
		</ImageSingle>
		<ImageSingle
			image="Affiches_018.jpg"
			bgColor="#df0613"
			affiche="true"
			legende="Pour la Mairie de Vincennes"
		>
		</ImageSingle>
		<CaseNext
			page="Société Générale"
		>
		</CaseNext>
	</div>
	

</template>

<script>

import CaseNext from '@/components/CaseNext.vue';
import CaseTop from '@/components/CaseTop.vue';
import ImageSingle from '@/components/ImageSingle.vue';
import $ from "jquery";

export default {
	name: 'CaseAffiches',
	components: {
		CaseTop, ImageSingle,CaseNext,
	},
	data () {
		return {
			
		}
	},
	mounted: function() {
		$("#app").addClass("noSticky")
	},
	destroyed: function() {
		$("#app").removeClass("noSticky")		
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";


</style>
