import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import vueHeadful from 'vue-headful';
import VueLazyload from 'vue-lazyload'
import VueWaypoint from 'vue-waypoint'

import '@/assets/scss/main.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLink, faHourglass, faAngleRight, faAngleDown, faMouseAlt, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faExternalLink, faHourglass, faAngleRight, faAngleDown, faMouseAlt, faArrowRight)

 
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('vue-headful', vueHeadful)

Vue.use(VueLazyload, {
	lazyComponent: true,
	error: 'loading.svg',
	loading: 'loading.svg',
	preLoad: 2,	
});

Vue.use(VueWaypoint)

Vue.config.productionTip = false

new Vue({
  // Enable routing
  router,
  render: h => h(App)
}).$mount('#app')