<template>
	<header>
		<div class="header_inner">
			<div class="header_inner_left">
				<div class="logo">
					<router-link :to="{ name: 'Réalisations'}">
						<img class="logo_img logo_black" src="@/assets/logo_default_b.svg" />
						<img class="logo_img logo_white" src="@/assets/logo_default_w.svg" />
					</router-link>
					<span class="baseline">création design graphique & digital</span>

				</div>
			</div>
			<div class="header_inner_right">
				<Menu/>
			</div>
		</div>
	</header>
</template>

<script>

import Menu from '@/components/Menu';
import $ from "jquery";

export default {
	name: 'Header',

	components: { 
		Menu
	},

	methods: {
		imgPath: function(image){
			return require("../assets/"+image);
		},
		handleScroll: function(){
			let $header = $("header");
			let $header_height = $header.outerHeight();

			if (window.pageYOffset > $header_height * 0.5 && !$("#app").hasClass("noSticky")) {
				$header.addClass("sticky");
			} else if (window.pageYOffset < $header_height / 10) {
				$header.removeClass("sticky");
			}
		}
	},

	created () {
		window.addEventListener('scroll', this.handleScroll);
	},

	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/main.scss";


</style>
