<template>
	<div class="section_realisation">
		<Realisation
			v-for="realisations in realisationItems" 
			v-bind:key="realisations.id"
			:titre="realisations.titre"
			:description="realisations.description"
			:lien="realisations.lien"
			:page="realisations.page"
			:lien_texte="realisations.lien_texte"
			:lien_liste="realisations.lien_liste"
			:image="realisations.image"
			:focus="realisations.focus"
			:color_transition="realisations.color_transition"
		>
		</Realisation>
	</div>
</template>

<script>

import Realisation from '@/components/Realisation'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

export default {
	name: 'RealisationSection',
	components: {
		Realisation
	},
	props: {
		realisationItems: {
			type: Array,
		}
	},
	methods: {
		Msnry: function(){
			var gridFocus = document.querySelector('.section_realisation.focus_section');
			var msnryFocus = new Masonry( gridFocus, {
				// options...
				itemSelector: '.element_container',
				originLeft: false,
				columnWidth: 1
			});

			imagesLoaded( gridFocus ).on( 'progress', function() {
				msnryFocus.layout();
			});

			var gridNormal = document.querySelector('.section_realisation.normal_section');
			var msnryNormal = new Masonry( gridNormal, {
				// options...
				itemSelector: '.element_container',
				horizontalOrder: false,
				columnWidth: 0,
				originLeft: true
			});

			imagesLoaded( gridNormal ).on( 'progress', function() {
				msnryNormal.layout();
			});
		}
	},
	mounted(){
		this.Msnry()
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/main.scss";

.section_realisation {

}


</style>
