<template>
	<section class="content_section" v-bind:class="{ image_section: isImage, inline_section: isInline, parallax_section: isParallax }" v-bind:style="{'background-color': bgColor, 'color': txtColor}" :id="id">
		<h2 class="head_title" v-if="title && headTitle" v-html="title"></h2>
		<div class="content_section_inner_text">
			<h2 v-if="title && !headTitle" v-html="title"></h2>
			<div class="list_content" v-bind:class="{simple_column: isOneColumn}" v-bind:style="{ 'text-align': alignText }">
				<ContentListElement
					v-bind:style="{'width': columnsWidth(columns)}"
					v-for="contentlistItem in orderBy(contentlistItems,orderlistItem)"
					v-bind:key="contentlistItem.id"
					:titre="contentlistItem.titre"
					:name="contentlistItem.name"
					:fonction="contentlistItem.fonction"
					:image="contentlistItem.image"
					:associe="contentlistItem.associe"
				>
				</ContentListElement>
				<slot></slot>
			</div>
		</div>
		<div v-if="image" class="content_section_inner_image" :class="imageClass" :style="{'background-image': 'url(' + require('../assets/images/'+image) + ')'}">
		</div>
	</section>

</template>

<script>

import ContentListElement from '@/components/ContentListElement';
import Vue2Filters from 'vue2-filters';

export default {
	name: 'ContentList',
	mixins: [Vue2Filters.mixin],
	components: {
		ContentListElement
	},
	data: function(){
		return {
			isInline: (this.inline),
			isImage: (this.image),
			isParallax: (this.parallax),
			isOneColumn: (this.columns == 1),
		};
	},
	props: {
		contentlistItems: {
			type: Array,
		},
		title: {
			type: String,
			default: '',
		},
		id: {
			type: String,
			default: '',
		},
		columns: {
			type: Number,
			default: 1,
		},
		alignText: {
			type: String,
			default: 'left',
		},
		orderlistItem: {
			type: String,
			default: 'id',
		},
		image: {
			type: String,
			default: '',
		},
		imageClass: {
			type: String,
			default: '',
		},
		inline: {
			type: String,
			default: '',
		},
		parallax: {
			type: String,
			default: '',
		},
		bgColor: {
			type: String,
			default: '',
		},
		txtColor: {
			type: String,
			default: '',
		},
		headTitle: {
			type: String,
			default: '',
		},
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
		columnsWidth: function(columns){
			columns = 100 / columns;
			return columns+'%';
		},
	},
	mounted(){
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.content_section {

	background-color: $white;
	padding: 5rem;
	color: $black;
	text-align: center;
	font-size: 2.4rem;

	@include breakpoint(lt-sm) {
		padding: 3.5rem;
	}

	h2 {
		font-family: $IsidoraBold;
		font-size: 4rem;
		position: relative;
		display: inline-block;
		margin-bottom: 4rem;

		&:after {
			content: "";
			position: absolute;
			bottom: -1.5rem;
			height: 0.4rem;
			width: 8rem;
			background-color: $black;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&_inner_text {
		max-width: 140rem;
		margin: auto;
	}

	.list_content {
		font-size: 2.4rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@include breakpoint (lt-md) {
		}

		@include breakpoint (lt-sm) {
			text-align: center !important;
		}

		&_element {
			margin-bottom: 2.5rem;
		}

		&.simple_column {
			flex-direction: column;
		}
	}

	&.image_section {
		display: flex;
		flex-wrap: wrap;
		min-height: $viewportheaderHeight;
		padding: 0;

		@include breakpoint (lt-md) {
			min-height: unset;
		}
		
		.content_section_inner_text {
			flex:1;
			justify-content: center;
			display: flex;
			flex-direction: column;
			padding: 5rem;
			box-sizing: border-box;
		}

		.content_section_inner_image {
			flex:1;
			background-position: center center;
			padding: 5rem;
			/*background-attachment: fixed;*/
			background-repeat: no-repeat;
			background-size: cover;
		}

		&.parallax_section {
			.content_section_inner_image {
				background-position: center right;
				background-attachment: fixed;
				background-size: 50% auto;
			}
		}

	}

	&.inline_section {
		.list_content {
			display: block;
			&_element {
				display: inline;
				text-transform: uppercase;
				line-height: 2em;

				&:after {
					content: "•";
					padding: 0.8rem;
				}

				&:last-of-type{
					&:after {
						content: none;
					}
				}
			}
		}
	}
}

.case {
	.content_section {
		h2 {
			font-size: 2.8rem;
			color: $black;
			margin-bottom: 2rem;
			&:after {
				display: none;
			}
		}

		h3 {
			font-size: 2.2rem;
			font-family: $IsidoraSemiBold;
		}

		&_inner_text {
			max-width: 110rem;
		}
		.list_content {
			font-size: 2.2rem;
			line-height: 1.3em;
			font-family: $IsidoraLight;
			color: $black;
		}
	}
}

</style>
