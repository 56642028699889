<template>
	<div class="SG_case">
		<vue-headful
			title="Slumberland | Société Générale"
			description=""
		/>
		<CaseTop
			title="Société Générale"
			subTitle="Conception de 3 plateformes multi-sites <br/>Mission : conseil, web design, développement, maintenance"
			cover="SG_cover.jpg"
		/>
		<ContentList
			alignText="center"
			:columns=1
			title="Mission"
			id="mission"
			bgColor="#f6f6f6"
		>
		Société Générale souhaitait créer une plateforme web multi-sites qui permette de garantir la cohérence tout en répondant aux besoins de chaque entité.
		</ContentList>
		<ImageSingle
			image="SG_001.jpg"
			boxed="true"
      mask="true"
		/>
		<ImageSingle
			image="SG_002.jpg"
			boxed="true"
			bgColor="#f6f6f6"
		/>
		<ImageSingle
			image="SG_003.png"
			parallax="true"
			bgColor="#f6f6f6"
		/>
		<ContentList
			alignText="center"
			:columns=1
			title="Solution"
			id="solution"
		>
		<p>Depuis 2009, nous avons créé 3 usines à sites, réunissant plus de 90 sites pour la Banque Privée, la Banque d’Investissement, le métier titres, Société Générale Assurances et la banque de détail en Afrique.</p>
		<h3>
			Cohérence et fléxibilité
		</h3>
		<p>
			La charte graphique simple et modulaire permet de composer des mises en pages variées pour répondre aux besoins locaux de chaque métier du groupe.
			<br/>
			Les besoins éditoriaux et fonctionnels ont été analysés pour apporter des solutions communes pertinentes.
		</p>
		<h3>
			Les bénéfices de la mutualisation
		</h3>
		<p>
			Une usine à sites permet de réduire le coût de création de chaque site unitaire.
			<br/>
			Mais elle permet aussi à chaque entité de bénéficier des évolutions demandées par les autres entités.

		</p>
		<h3>
			Le bon suivi
		</h3>
		<p>
			Nous réalisons deux mises en ligne par mois de maintenance corrective et évolutive, des développements additionnels réguliers, et une refonte ou évolution graphique tous les deux ans en moyenne.
		</p>
		</ContentList>
		<Carousel
			:carouselImages="[{ image: 'SG_004.jpg'},{ image: 'SG_005.jpg'},{ image: 'SG_006.jpg'},{ image: 'SG_007.jpg'}]"
			boxed="true"
      mask="true"
		/>
		<VideoSingle
			video="pb_video.mp4"
      boxed="true"
      bgColor="#f6f6f6"
      mask="true"
      autoplay="true"
		/>
		<ImageSingle
			image="SG_009.png"
			boxed="true"
		/>
		<ContentList
			alignText="center"
			:columns=1
			title="Résultat"
			id="resultat"
			bgColor="#f6f6f6"
		>
		380 contributeurs du groupe Société Générale ont créé plus de 15 000 pages sur plus de 90 sites.
		<br/>
		Lorsque le groupe Société Générale a lancé son nouveau territoire de communication en 2018, nous l’avons déployé en une matinée de façon cohérente sur l’ensemble des sites.
		</ContentList>
		<CaseNext
			page="Festival du cinéma américain de Deauville"
		>
		</CaseNext>

	</div>
	

</template>

<script>

import CaseTop from '@/components/CaseTop.vue';
import CaseNext from '@/components/CaseNext.vue';
import ContentList from '@/components/ContentList.vue';
import ImageSingle from '@/components/ImageSingle.vue';
import VideoSingle from '@/components/VideoSingle.vue';
import Carousel from '@/components/Carousel.vue';

export default {
	name: 'CaseSG',
	components: {
		CaseTop, 
		ContentList,
    ImageSingle,
    VideoSingle,
    CaseNext,
		Carousel
	},
	data () {
		return {
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";


</style>
