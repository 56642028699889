<template>
	<div class="Sonance_Case">
		<vue-headful
			title="Slumberland | Sonance Audition"
			description=""
		/>
		<CaseTop
			title="Sonance Audition"
			subTitle="Design 360° <br/>Mission : conseil, création de la marque, déclinaison des supports, accompagnement"
			cover="Sonance_cover.jpg"
		/>
		<ContentList
			alignText="center"
			:columns=1
			title="Mission"
			id="mission"
		>
			Sonance Audition, réseau d’audioprothésistes indépendants, nous a confié en 2014, la création de son identité visuelle et de son territoire de marque, avec pour ambition : un positionnement premium, un engagement de qualité, un cadre valorisant l’esthétique contemporaine et centré sur l’accueil du patient. 
		</ContentList>
		<ImageSingle
			image="Sonance_007.jpg"
			parallax="true"
		>
		</ImageSingle>
		<ContentList
			alignText="center"
			:columns=1
			title="Identité"
			id="identite"
			bgColor="#f6f6f6"
		>
			Le design de marque élégant et pur, tranche avec le marché concurrent. Le symbole est un repère puissant. Il donne du sens au parcours sonore. Il crée un lien intuitif avec l’univers de style émotionnel iconographique. Le ton rédactionnel, affirmé et singulier, marque les esprits. 
		</ContentList>
		<ImageSingle
			image="Sonance_001.svg"
			boxed="true"
			style="padding-top:0;padding-bottom:0"
			animated="false"
		>
		</ImageSingle>
		<ImageSingle
			image="Sonance_002.png"
			parallax="true"
		>
		</ImageSingle>
		<ContentList
			alignText="center"
			:columns=1
			title="Signalétique"
			id="signaletique"
		>
			Un principe signalétique efficace et modulable pour chaque espace architectural des laboratoires.
		</ContentList>
		<ImageList
			:imagelistItems="[{ image: 'Sonance_003.jpg'},{ image: 'Sonance_004.jpg'},{ image: 'Sonance_005.jpg'},{ image: 'Sonance_006.jpg'}]"
			:columns=2
		>
		</ImageList>
		<ContentList
			alignText="center"
			:columns=1
			title="Édition"
			id="edition"
		>
			Une diversité d’outils d’éditions, d’affiches pédagogiques et ludiques pour accompagner le patient, clarifier le discours du praticien et valoriser son métier.
		</ContentList>
		<ImageSingle
			image="Sonance_008.jpg"
			parallax="true"
			bgColor="#f6f6f6"
		>
		</ImageSingle>
		<ContentList
			alignText="center"
			:columns=1
			title="Site internet"
			id="site"
		>
			Création du site internet pédagogique qui présente l’esprit Sonance Audition.
		</ContentList>

		<Carousel
			:carouselImages="[{ image: 'Sonance_009.jpg'},{ image: 'Sonance_010.jpg'},{ image: 'Sonance_013.jpg'},{ image: 'Sonance_014.jpg'}]"
			mask="desktop"
			bgColor="#f6f6f6"
      boxed="true"
		/>
		<ContentList
			alignText="center"
			:columns=1
			title="Packaging"
			id="packaging"
		>
			Une gamme de soins spécifique, déclinée avec les ingrédients de la charte graphique, renforce l’harmonie esthétique globale des espace des laboratoire Sonance Audition.
		</ContentList>
		<ImageSingle
			image="Sonance_011.jpg"
			boxed="true"
			center="true"
		>
		</ImageSingle>
		<ContentList
			alignText="center"
			:columns=1
			title="Résultat"
			id="resultat"
			bgColor="#f6f6f6"
		>
			Depuis Janvier 2020, plus de 90 centres Sonance Audition ont été ouverts en 5 ans sur le territoire français.
		</ContentList>
		<CaseNext
			page="Création affiches"
		>
		</CaseNext>
	</div>
	

</template>

<script>

import CaseTop from '@/components/CaseTop.vue';
import CaseNext from '@/components/CaseNext.vue';
import ContentList from '@/components/ContentList.vue';
import ImageSingle from '@/components/ImageSingle.vue';
import ImageList from '@/components/ImageList.vue';
import Carousel from '@/components/Carousel.vue';

export default {
	name: 'CaseSonance',
	components: {
		CaseTop, ContentList, ImageSingle, CaseNext, ImageList, Carousel
	},
	data () {
		return {
		}
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";


</style>
