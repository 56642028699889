<template>
  <section v-observe-visibility="videoLaunch" class="video_section" v-bind:class="{video_section_boxed: isBoxed}" :style="{'background-color':bgColor}" >
    <div class="video_section_simple" ref="video_section_simple">
      <video v-if="isAutoplayed" loop autoplay muted :src="videoPath(video)" ref="videoSimple" v-bind:class="{border_desktop: hasMask}" v-bind:poster="imgPath(poster)"></video>
      <video v-else controls="true" :src="videoPath(video)" ref="videoSimple" v-bind:class="{border_desktop: hasMask}" v-bind:poster="imgPath(poster)"></video>
      <div v-if="legende" class="video_section_legende" ref="legende" v-html="legende"></div>
    </div>
  </section>

</template>

<script>

import Vue2Filters from 'vue2-filters';
import {TweenMax, Expo} from 'gsap';
import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

export default {
  name: 'VideoSingle',
  mixins: [Vue2Filters.mixin],
  components: {
  },
  data: function(){
    return {
      isBoxed: (this.boxed),
      hasMask: (this.mask),
      isAutoplayed: (this.autoplay),
      isVisible: true
    };
  },
  props: {
    video: {
      type: String,
      default: '',
    },
    poster: {
      type: String,
      default: '',
    },
    boxed: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    autoplay: {
      type: String,
      default: '',
    },
    legende: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
  },
  methods: {
    videoPath: function(video){
      return require('../assets/videos/'+video);
    },
    imgPath: function(image){
      return require('../assets/images/'+image);
    },
    videoLaunch (isVisible, entry) {
      this.isVisible = isVisible
      if(isVisible === true && this.autoplay == "true") {
        setTimeout(function() {
          entry.target.getElementsByTagName("video")[0].play();
        }, 500);
      }
      else {
        entry.target.getElementsByTagName("video")[0].pause();
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.video_section {
  overflow: hidden;
  video {
    width: 100%;
  }
  &_simple {
    position: relative;
    .video_section_legende {
      position: relative;
      padding: $padding-simple 0;
      color: black;
    }
  }
  &_boxed {
    text-align: center;
    padding:5rem;
    @include breakpoint(lt-sm) {
      padding: 3.5rem;
    }
    video {
      max-width: 110rem;
      margin: auto;
    }
  }
}

</style>
