<template>

    <div class="scrolldown">
        <span class="scrolldown_icon">
            <font-awesome-icon :icon="['far', 'angle-down']"/>
        </span>
    </div>

</template>

<script>

    export default {
        name: 'Scrolldown',
        components: {
        },
        props: [],
    };
</script>

<style scoped lang="scss">
@import "@/assets/scss/main.scss";

.scrolldown{
    position: fixed;
    left: 50%;
    bottom: 5rem;
    color: #000;
    cursor: pointer;
    z-index: $z_header;

    &_icon {
        font-size: 2.4rem;
        position: absolute;
        transform: translateY(0);
        @include transition();
        animation: bounce 1.5s ease-in-out infinite alternate;

        svg {
            color: $black;
            filter: drop-shadow(0px 2px 7px $white);
        }
    }
}

.lightTheme {
    .scrolldown {
        &_icon {
            svg {
                color: $white;
                filter: drop-shadow(0px 2px 7px $black);
            }
        }
    }
}
</style>