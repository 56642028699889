<template>
	<footer>
		<div class="footer_inner">
			<div class="footer_menu">
				<menu>
					<menuitem 
					v-for="menuItem in orderBy(menuItems,'id')" 
					v-bind:key="menuItem.id">
						<router-link v-if="menuItem.path" :to="{ name: menuItem.name}">
							{{menuItem.name}}
						</router-link>
						<a v-if="menuItem.url" :href="menuItem.url">
							{{menuItem.name}}
						</a>
						<a v-if="menuItem.contactMail" class="contact_mail">
							{{menuItem.name}}
						</a>
					</menuitem>
				</menu>
		</div>
		</div>
	</footer>
</template>

<script>

import Vue2Filters from 'vue2-filters';

export default {
	name: "footer",
	mixins: [Vue2Filters.mixin],
	data () {
		return {
			title: "Menu",
			menuItems: [
				{
					id: 0,
					name: "Mentions légales",
					path:"/mentions-legales"
				}
			]
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/main.scss";

footer {
	height: 6rem;
	overflow: hidden;
	.footer_inner {
		text-align: center;
		padding: $padding-simple;
		.footer_menu {
			menu {
				padding: 0;
				
				menuitem {
					font-size: 1.4rem;
					font-family: $IsidoraSemiBold;
					border-left: 1px solid $black;
					padding-left: 1rem;
					margin-left: 1rem;
					
					&:first-of-type {
						border-left: none;
						padding-left: 0;
						margin-left: 0;
					}
				}
			}
		}
	}
}

.case footer {
	display: none;
}

</style>
