<template>
	<div class="Deauville_case">
		<vue-headful
			title="Slumberland | Festival du cinéma américain de Deauville"
			description=""
		/>
		<CaseTop
			title="Festival du cinéma américain de Deauville"
			subTitle="Création identité visuelle, charte graphique et affiche de la 46ème édition"
			cover="Deauville_cover.jpg"
		/>
		<ContentList
			alignText="center"
			:columns=1
			title="Mission"
			id="mission"
			boxed="true"
		>
			Pour la 45e édition, le Public Système Cinéma nous a confié la refonte de la marque du Festival du Cinéma Américain de Deauville, avec pour but : une présence visuelle forte qui s’impose dans le paysage des grands festivals de cinéma internationaux.
		</ContentList>
		<ImageList
			:imagelistItems="[{ image: 'Deauville_001.jpg'},{ image: 'Deauville_002.jpg'},{ image: 'Deauville_003.jpg'},{ image: 'Deauville_004.jpg', legende: '© Olivier Vigerie'},{ image: 'Deauville_005.jpg', legende: '© Olivier Vigerie'},{ image: 'Deauville_006.jpg'},{ image: 'Deauville_007.jpg', legende: '© Olivier Vigerie'},{ image: 'Deauville_008.jpg', legende: '© Olivier Vigerie'}]"
			:columns=2
		>
		</ImageList>
		<ContentList
			alignText="center"
			:columns=1
			title="La réponse graphique"
			id="reponse"
		>
			La silhouette du parasol dessinée façon "pochoir géométrique" laisse entrer la lumière et apporte du relief. La partie typographique du logotype est modulable pour une lisibilité optimale ; elle répond à toutes les exigences des différents marquages (signalétique, scénographie, édition et web).
		</ContentList>
		<ImageSingle
			image="Deauville_009.svg"
			boxed="true"
			animated="false"
		>
		</ImageSingle>
		<ImageSingle
			image="Deauville_010.jpg"
			parallax="true"
			legende="© Olivier Vigerie"
		>
		</ImageSingle>
		<CaseNext
			page="Maison Francis Kurkdjian"
		>
		</CaseNext>
	</div>
	

</template>

<script>

import CaseTop from '@/components/CaseTop.vue';
import CaseNext from '@/components/CaseNext.vue';
import ContentList from '@/components/ContentList.vue';
import ImageSingle from '@/components/ImageSingle.vue';
import ImageList from '@/components/ImageList.vue';

export default {
	name: 'CaseDeauville',
	components: {
		CaseTop, ContentList, ImageSingle, ImageList,CaseNext,
	},
	data () {
		return {
			
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";


</style>
