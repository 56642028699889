<template>
	<section class="image_section" v-bind:class="{image_section_boxed: isBoxed, image_section_affiche: isAffiche}" :style="{'background-color':bgColor}" v-waypoint="{ active: true, callback: changeColorHeader, options: image_sectionIntersectionOptions }">
		<div v-if="parallax || affiche" :id="image" class="image_section_parallax" ref="parallax" v-lazy:background-image="require('../assets/images/'+image)">
			<div v-if="legende && !affiche" class="image_section_legende" v-bind:class="{dark: isDarkLegende}" ref="legende" v-html="legende"></div>
			<div v-else-if="affiche && legende" class="image_section_affiche_legende" ref="legende" v-bind:class="{image_section_affiche_legende_light: isLight}" v-html="legende"></div>
			<div v-if="affiche" class="image_section_affiche_next" v-bind:class="{image_section_affiche_next_light: isLight}">
				<span class="scrolldown_icon">
					<font-awesome-icon :icon="['far', 'mouse-alt']"/>
				</span>
			</div>
		</div>
		<div v-else-if="!parallax && !affiche" :id="image" class="image_section_simple">
			<img
          v-lazy="imgPath(image)"
          ref="imageSimple"
          v-waypoint="{ active: true, callback: animViewportSingleImage}"
          v-bind:class="{border_desktop: hasMask}"
      >
			<div v-if="legende" class="image_section_legende" v-bind:class="{dark: isDarkLegende}" ref="legende" v-html="legende"></div>
		</div>
	</section>

</template>

<script>

import Vue2Filters from 'vue2-filters';
import {TweenMax, Expo} from 'gsap';
import $ from "jquery";

export default {
	name: 'ImageSingle',
	mixins: [Vue2Filters.mixin],
	components: {
	},
	data: function(){
		return {
      hasMask: (this.mask),
			isBoxed: (this.boxed),
			isAffiche: (this.affiche),
			isRatio: (this.ratioAffiche),
			isAnimated: (this.animated == "true"),
			isLight: (this.light == "true"),
			isDarkLegende: (this.darkLegende == "true"),
			image_sectionIntersectionOptions: {
				root: null,
				rootMargin: '-4% 0px -94% 0px',
				thresholds: 0
			}
		};
	},
	props: {
		image: {
			type: String,
			default: '',
		},
    mask: {
      type: String,
      default: '',
    },
		parallax: {
			type: String,
			default: '',
		},
		boxed: {
			type: String,
			default: '',
		},
		affiche: {
			type: String,
			default: '',
		},
		bgColor: {
			type: String,
			default: 'transparent',
		},
		animated: {
			type: String,
			default: "true",
		},
		light: {
			type: String,
			default: "true",
		},
		legende: {
			type: String,
			default: "",
		},
		ratioAffiche: {
			type: String,
			default: "",
		},
		firstAffiche: {
			type: String,
			default: "false",
		},
		darkLegende: {
			type: String,
			default: "",
		},
	},
	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
		animViewportSingleImage ({ going, direction }) {
			if (this.isAnimated == true) {
				let $elIMG = this.$refs.imageSimple;

				if (going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP) {
					TweenMax.fromTo(
						$elIMG,
						1,
						{	
							delay: 0.25,
							opacity: 0,
							translateY: 200,
						},
						{	
							opacity: 1,
							translateY: 0,
						}
					);
				} else if (going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_BOTTOM) {
					TweenMax.fromTo(
						$elIMG,
						1,
						{	
							delay: 0.25,
							opacity: 0,
							translateY: -200,
						},
						{	
							opacity: 1,
							translateY: 0,
						}
					);
				} else if (going === this.$waypointMap.GOING_OUT && direction === this.$waypointMap.DIRECTION_TOP) {
					TweenMax.fromTo(
						$elIMG,
						1,
						{	
							delay: 0.25,
							opacity: 0,
							translateY: -200,
						},
						{	
							opacity: 1,
							translateY: 0,
						}
					);
				} else if (going === this.$waypointMap.GOING_OUT && direction === this.$waypointMap.DIRECTION_BOTTOM) {
					TweenMax.fromTo(
						$elIMG,
						1,
						{	
							delay: 0.25,
							opacity: 0,
							translateY: 200,
						},
						{	
							opacity: 1,
							translateY: 0,
						}
					);
				}
			}
		},
		changeColorHeader ({ going, direction }) {
			if(this.light != "true") {
				if (going === this.$waypointMap.GOING_IN) {
					$("#app").removeClass("lightHeader");
				}

				if (going === this.$waypointMap.GOING_OUT) {
					if (direction === this.$waypointMap.DIRECTION_BOTTOM || direction === this.$waypointMap.DIRECTION_TOP) {
						$("#app").addClass("lightHeader");
					}
				}
			}

			if(this.firstAffiche == "true") {
				if ((going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP)) {
					$("#app").addClass("lightHeader");
					$("#app").removeClass("protectedHeader");
				}
				else if (going === this.$waypointMap.GOING_OUT && direction === this.$waypointMap.DIRECTION_BOTTOM) {
					$("#app").removeClass("lightHeader");
					$("#app").addClass("protectedHeader");
				}
			}

		},
		positionLegende (){
			if(this.isAffiche) {
				if (this.ratioAffiche) {
					let leftValue = "calc("+this.ratioAffiche * 100 / 2+"vh + 50% - 5rem)";
					this.$refs.legende.style.left = leftValue;
				}
			}
		}
	},
	updated () {
		this.positionLegende();
	}, 

	mounted () {
		this.positionLegende();
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.image_section {
	overflow: hidden;
	img {
		width: 100%;
	}
	&_simple {
		position: relative;
		.image_section_legende {
			position: absolute;
			right: 0;
			bottom: 0;
			padding: $padding-simple;
			background-color: transparent;
			color: $white;
			transition: $E-defaultTime $E-default;
			&.dark {
				color: $black;
			}
		}
		&:hover {
			.image_section_legende {
				background-color: rgba(0,0,0,0.5);
				color: $white;
				transition: $E-defaultTime $E-default;
			}
		}		
	}
	&_parallax {
		height: $viewportheaderHeight;
		background-attachment: fixed;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		@include breakpoint(lt-sm) {
			background-attachment: initial;
			height: 50vh;
		}
		.image_section_legende {
			position: absolute;
			right: 0;
			bottom: 0;
			padding: $padding-simple;
			background-color: transparent;
			color: $white;
			transition: $E-defaultTime $E-default;
			&.dark {
				color: $black;
			}
		}
		&:hover {
			.image_section_legende {
				background-color: rgba(0,0,0,0.5);
				color: $white;
				transition: $E-defaultTime $E-default;
			}
		}
	}

	&_boxed {
		text-align: center;
		padding:5rem;
		@include breakpoint(lt-sm) {
			padding: 3.5rem;
		}
		img {
			max-width: 110rem;
			margin: auto;
		}
	}

	&_affiche {
		height: 100vh;
		box-sizing: border-box;
		position: relative;
		@include breakpoint(lt-sm) {
			padding: 0 $padding-double;
		}
		.image_section_parallax {
			height: 100%;
			width: 100%;
			background-attachment: fixed;
			background-size: auto $boxedparallaxHeight;
			clip-path: inset(0 0 0 0);
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			@include breakpoint(lt-sm) {
				background-size: auto 60vh;
				background-position: center center;
				position: relative;
				background-attachment: initial;
			}
		}

		&_next{
			position: fixed;
			left: 50%;
			bottom: 3rem;
			color: #000;
			cursor: default;
			z-index: 1;
			@include transition();
			animation: SlideInFromBottomMinor  1s ease-in-out infinite alternate;

			@include breakpoint(lt-sm) {
				display: none;
			}

			.scrolldown_icon {
				font-size: 2rem;
				position: relative;
				transform: translateY(0);

				svg {
					color: $black;
				}
			}

			&_light {
				.scrolldown_icon {
					svg {
						color: $white;
					}
				}
			}
		}

		&_legende {
			position: fixed;
			left: calc(66.7vh / 2 + 50% - 5rem);
			padding-left: 3rem;
			max-width: 10rem;
			white-space: normal;
			top: calc(100vh - 8rem);
			transform: translateY(-100%);
			z-index: 1;
			display: inline-block;
			color: $black;

			@include breakpoint(lt-sm) {
				position: absolute;
				left: 50% !important;
				top: calc(80vh + 1rem);
				transform: translateY(0) translateX(-50%);
				padding: 1rem;
				box-sizing: border-box;
				max-width: 80vw;
				width: auto;
			}

			&_light {
				color: $white;
			}
		}
	}
}

</style>
