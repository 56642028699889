<template>
	<section class="carousel" :style="{'background-color':bgColor}">
		<div v-bind:class="{carousel_boxed: isBoxed, border_desktop: hasMask}">
			<div class="carousel_container">
				<carousel
					:key="carouselKey"
					class="carousel_content"
					:items=1
					:nav=false
					:dots=false
					:autoplay=true
					:autoplayTimeout=2000
					:loop=true
					:smartSpeed=750
				>
					<img
						v-for="carouselImage in carouselImages"
						v-bind:key="carouselImage.id"
						class="carousel_list_element" 
						:src="imgPath(carouselImage.image)"
					/>
				</carousel>
			</div>
		</div>
	</section>

</template>

<script>

import $ from "jquery";
import Vue2Filters from 'vue2-filters';
import carousel from 'vue-owl-carousel2'

export default {
	name: 'Carousel',
	mixins: [Vue2Filters.mixin],
	components: {
		carousel,
	},
	data: function(){
		return {
      hasMask: (this.mask),
      isBoxed: (this.boxed),
			carouselKey: 0,
			svgKey: 0,
		};
	},
	props: {
		carouselImages: {
			type: Array,
		},
		image: {
			type: String,
			default: '',
		},
    mask: {
      type: String,
      default: '',
    },
    boxed: {
      type: String,
      default: '',
    },
		bgColor: {
			type: String,
			default: 'transparent',
		},
	},

	methods: {
		imgPath: function(image){
			return require('../assets/images/'+image);
		},
		carouselMask: function(){
      setTimeout(() => {
        this.carouselMask();
      }, 250);
		},
	},
	mounted(){
		this.carouselMask();
	},
	created () {
		window.addEventListener('resize', this.carouselMask);
	},

	destroyed () {
		window.removeEventListener('resize', this.carouselMask);
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/main.scss";

.carousel {
	position: relative;
  padding: 5rem;

	&_boxed {
		max-width: 110rem;
		position: relative;
		margin: auto;
		@include breakpoint(lt-sm) {
			padding: 3.5rem;
		}
		.carousel_container {
			.carousel_content {
				position: relative;
				box-sizing:border-box;
			}
		}
	}
}

</style>
